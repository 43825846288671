<template>
  <section>

    <section class="section">
      <div class="container">

      <h1 class="title is-marginless">Hi {{ username }}!</h1>
      <h2 class="subtitle is-5 is-marginless">Recent Events</h2>

      <Events :hasSearch="false" :onlyRecent="true" /> 

      </div>
    </section>

  </section>
</template>

<script>

import Events from '@/components/events/Events'

export default {

  name: 'HomePage',

  components: {
    Events,
  },

  computed: {

    username() {
      return this.$store.getters.username
    },

  },

}

</script>
