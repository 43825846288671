<template>
  <section>

    <EventPanel :eventName="eventName" v-slot="{ event }">
      <HitPanel :eventName="eventName" v-slot="{ loading, methods: { post }}">
        
        <h1 class="title">{{ event.name }}</h1>
        <h2 class="subtitle">New Hit</h2>

        <div class="box is-ancored">
          <HitForm :event="event" :loading="loading" @post="post" />
        </div>

      </HitPanel>
    </EventPanel>

  </section>
</template>

<script>

import EventPanel from '@/components/events/EventPanel'
import HitForm from '@/components/hits/HitForm'
import HitPanel from '@/components/hits/HitPanel'

export default {

  name: 'HitCreate',

  components: {
    EventPanel,
    HitForm,
    HitPanel,
  },

  data() {

    let { eventName } = this.$route.params

    return {
      eventName
    }

  },

}

</script>