<template>
  <div id="app">

    <Navbar v-if="isLogged" />

    <body class="has-background-background" 
      :class="{ 'has-navbar-fixed-bottom': isLogged,'has-navbar-fixed-top': isLogged}"
    >
      <router-view />
    </body>

    <NavbarBottom
      v-if="isLogged"
      class="is-hidden-tablet"
    />

  </div>
</template>

<script>

import Navbar from '@/components/app/Navbar'
import NavbarBottom from '@/components/app/NavbarBottom'
import Login from '@/views/Login'

export default {

  name: 'App',

  components: {
    Navbar,
    Login,
    NavbarBottom,
  },

  computed: {

    isLogged() {
      return this.$store.getters.isLogged
    }

  },

}

</script>

<style lang="scss" scoped>

@import "@/assets/scss/variables/all";

body {
  min-height: 100vh;
}

</style>
