<template>
  <section>

    <EventPanel :eventName="eventName" v-slot="{ event, loading, methods: { post, patch }}">

      <h1 class="title">{{ event.name }}</h1>
      <h2 class="subtitle">Edit</h2>

      <div class="box is-ancored">
        <EventForm :base="event" :loading="loading" @patch="patch" @post="post" />
      </div>

    </EventPanel>

  </section>
</template>

<script>

import EventPanel from '@/components/events/EventPanel'
import EventForm from '@/components/events/EventForm'

export default {

  name: 'EventEdit',

  components: {
    EventPanel,
    EventForm,
  },

  data() {

    let { eventName } = this.$route.params

    return {
      eventName
    }

  },

}

</script>