<template>
  <div>

    <spinner v-if="loadingFetch" />

    <div v-else-if="result || !id">
      <slot :hit="result" :loading="loading" :methods="{ get, post, patch, del }" />
    </div>

    <div v-else>
      Hit with id <b>{{id}}</b> not found
    </div>
    
  </div>
</template>

<script>

export default {

  name: 'HitPanel',

  props: {

    eventName: {
      type: String,
      dafault: null,
    },

    id: {
      dafault: null,
    },

  },

  data() {

    return {
      loadingFetch: false,
      loadingPost: false,
      loadingPatch: false,
      loadingDelete: false,
      result: null,
    }
  
  },

  created() {
    if (this.id) this.get()
  },

  computed: {

    loading() {
      return this.loadingFetch || this.loadingPost || this.loadingPatch || this.loadingDelete
    },

  },

  methods: {

    get() {

      this.loadingFetch = true

      this.$store.dispatch('GET_HIT', { eventName: this.eventName,  hitId: this.id })
        .then(res => {
          this.result = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetch = false
        })

    },

    post(payload) {

      this.loadingPost = true

      return this.$store
        .dispatch("POST_HIT", { eventName: this.eventName, payload })
        .then(res => {
          this.$router.push({ name: 'event', params: { eventName: this.eventName } })
        })
        .catch(error => {

        })
        .then(() => {
          this.loadingPost = false
        })

    },

    patch(payload) {

      this.loadingPatch = true

      return this.$store
        .dispatch("PATCH_HIT", { eventName: this.eventName, hitId: this.id, payload })
        .then(res => {
          this.$router.push({ name: 'event', params: { eventName: this.eventName } })
        })
        .catch(error => {

        })
        .then(() => {
          this.loadingPatch = false
        })

    },

    del() {

      this.loadingDelete = true

      this.$store.dispatch('DELETE_HIT', { id: this.id })
        .then(res => {
          this.$router.push({ name: 'event', params: { eventName: this.eventName } })
          // this.result = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingDelete = false
        })

    },

  }

}

</script>