<template>
  <div>

    <spinner v-if="loadingFetch" />

    <slot v-else-if="result" :hits="result" :value="totalValue"  :loading="loading" :methods="{ del }" />

    <div v-else>
      Hits for event <b>{{eventName}}</b> not found
    </div>
    
  </div>
</template>

<script>

export default {

  name: 'HitsPanel',

  props: {

    eventName: {
      dafault: null,
    },

    payload: {
      type: Object,
      dafault() {
        return {}
      },
    },

  },

  data() {

    return {
      loadingFetch: false,
      loadingDelete: false,
      result: [],
    }
  
  },

  created() {
    if (this.eventName) this.fetch()
  },

  computed: {

    loading() {
      return this.loadingFetch || this.loadingDelete
    },

    totalValue() {
      let value = this.result.reduce((total, hit) => {
        return total += hit.value 
      }, 0)

      let valueRounded = Math.round((value + Number.EPSILON) * 100) / 100;
      return valueRounded
    },

  },

  methods: {

    fetch() {

      this.loadingFetch = true

      this.$store.dispatch('GET_HITS', { eventName: this.eventName, payload: {...this.payload } })
        .then(res => {
          this.result = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetch = false
        })
    },

    del(hitId) {

      this.loadingDelete = true

      this.$store.dispatch('DELETE_HIT', { eventName: this.eventName, hitId: hitId })
        .then(res => {
          this.fetch()
        })
        .catch(() => {})
        .then(() => {
          this.loadingDelete = false
        })

    },

  },

  watch: {

    payload() {
      this.fetch()
    },

  }

}

</script>