const colorsByName = {
  'red-salsa': 'hsla(359, 94%, 62%, 1)',
  'orange-red': 'hsla(21, 89%, 56%, 1)',
  'yellow-orange': 'hsla(33, 94%, 55%, 1)',
  'mango-tango': 'hsla(20, 94%, 63%, 1)',
  'maize-crayola': 'hsla(42, 93%, 64%, 1)',
  'pistachio': 'hsla(94, 38%, 59%, 1)',
  'zomp': 'hsla(162, 43%, 46%, 1)',
  'cadet-blue': 'hsla(178, 30%, 43%, 1)',
  'queen-blue': 'hsla(208, 25%, 45%, 1)',
  'cg-blue': 'hsla(198, 61%, 39%, 1)',
} 

export {
  colorsByName,
}