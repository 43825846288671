<template>
  <div>

    <div class="columns is-mobile">

      <div class="column">
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Value</p>
              <p class="subtitle">{{ hit.value }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Date</p>
              <p class="subtitle">{{ dateH }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Notes</p>
              <p class="subtitle">{{ hit.notes || '--' }}</p>
            </div>
          </div>
        </nav>
      </div>

      <div class="column has-text-right is-narrow">
        
        <div class="columns">
          <div class="column">
            <span class="tag is-medium is-light">{{ hit.id }}</span>
          </div>
          <div class="column">
            <button class="button is-small" @click="goToEdit">
              <span class="icon has-text-info">
                <fa :icon="[ 'fas', 'pencil-alt' ]"/>
              </span>
            </button>
          </div>
          <div class="column">
            <button class="button is-small" @click="deleteHit">
              <span class="icon has-text-danger">
                <fa :icon="[ 'fas', 'minus' ]"/>
              </span>
            </button>
          </div>
        </div>

      </div>


    </div>

  </div>
</template>

<script>

export default {

  name: 'hit',

  props: {

    hit: {
      type: Object
    }

  },

  computed: {

    dateH() {
      return new Date(this.hit.date + 'Z').toLocaleString()
    },

  },

  methods: {

    deleteHit() {
      let c = window.confirm(`Delete hit ${this.hit.id}?`)
      if (!c) return
      this.$emit('delete', this.hit.id)
    },

    goToEdit() {
      this.$router.push({ name: 'hitEdit', params: { hitId: this.hit.id } })
    },

  },

}

</script>
