<template>
  <div class="spinner"><div></div><div></div><div></div></div>
</template>

<script>

export default {

  name: 'Apinner',

}
</script>
