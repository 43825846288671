<template>
  <section>

    <section class="section has-navbar-fixed-top">
      <div class="container">
        <router-view></router-view>
      </div>
    </section>

  </section>
</template>

<script>

export default {

  name: 'EventsWrapper',

  computed: {

    useEventNavbar() {
      return this.$route.name == 'event'
    },

    route() {
      return this.$route
    },

    eventName() {
      return this.$route.params.eventName
    },

  },

  methods: {

    goToEventEdit() {
      this.$router.push({ name: 'eventEdit', params: { eventName: this.eventName } })
    },

    goToHitCreate() {
      this.$router.push({ name: 'hitsNew', params: { eventName: this.eventName } })
    },

  }

}

</script>
