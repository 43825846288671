import axios from 'axios'
import router from '@/router'

export const TOKEN_NAME = 'EVENTS_TOKEN'


let http = {

  axios : null,

  install : function (Vue) {

    this.axios = axios.create({
      baseURL: (process.env.NODE_ENV == 'development') ? 
        process.env.VUE_APP_LOCAL_ROOT_API
        : '/api/',

      headers: {
        Authorization: window.localStorage.getItem(TOKEN_NAME)
        ? `Bearer ${window.localStorage.getItem(TOKEN_NAME)}`
        : null,
      },

    })

    this.axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
      
      if (error.response.status == 401) {
        router.push({ name: 'logout' }).catch(e => {})
      }

      return Promise.reject(error)
    })

    Vue.prototype.$http = this.axios

  },

}

export { http }