<template>
  <section>

    <EventPanel v-slot="{ loading, methods: { post }}">

      <h1 class="title is-4">New Event</h1>

      <div class="box is-ancored">
        <EventForm :loading="loading" @post="post" />
      </div>

    </EventPanel>

  </section>
</template>

<script>

import EventPanel from '@/components/events/EventPanel'
import EventForm from '@/components/events/EventForm'

export default {

  name: 'EventCreate',

  components: {
    EventPanel,
    EventForm,
  },

}

</script>