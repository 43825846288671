import Vue from 'vue'

let BasicFilter = Vue.component('basic-filter', {

  props: {

    values: {
      type: Array,
      default() {
        return []
      }
    },

    fieldSearch: {
      type: String
    },

    classes: {
      type: Array,
      default() {
        return []
      }
    },

    disabled: {
      type: Boolean,
      default: false,
    },

  },

  data() {

    return {
      filter: null
    }

  },

  computed: {

    filtered() {
      if (!this.filter) return this.values
      return this.values.filter(value => value[this.fieldSearch] && value[this.fieldSearch].toLowerCase().includes(this.filter.toLowerCase()))
    }

  },

  methods: {
  
    compositionUpdate: function(event) {
      this.filter = event.data
    },

  },

  template: `
    <section>
      <div :class="classes">
        <div v-if="!disabled" class="field is-grouped">
          <div class="control is-expanded">
            <input class="input" type="text" v-model="filter" @compositionupdate="compositionUpdate($event)" placeholder="Search">
          </div>
          <slot name="button"></slot>
        </div>
      </div>
      <slot :filtered="filtered" ></slot>
    </section>
  `
})

export {
  BasicFilter,
}