<template>
  <section>

    <EventPreview
      class="box is-ancored"
      :_event="event"
      v-for="event in events"
      :key="event.name"
      :hasChart="hasChart"
    />

  </section>
</template>

<script>

import EventPreview from '@/components/events/EventPreview'

export default {

  name: 'EventList',

  components: {
    EventPreview,
  },

  props: {

    events: {
      type: Array,
      default() {
        return []
      }
    },

    hasChart: {
      type: Boolean,
      default: true
    },

  },

}

</script>