import { 
  http,
} from '@/include/http'

const state = {}

const getters = {}

const actions = {

  GET_EVENTS({ state, commit }, payload =  {} ) {
    return http.axios.get('events').then(res => res.data)
  },

  GET_RECENT_EVENTS({ state, commit }, payload =  {} ) {
    return http.axios.get('events/recent').then(res => res.data)
  },

  GET_EVENT({ state, commit }, { eventName,  payload =  {} } ) {
    return http.axios.get(`events/${eventName}`).then(res => res.data)
  },

  GET_HIT({ state, commit }, { eventName, hitId } ) {
    return http.axios.get(`events/${eventName}/hits/${hitId}`).then(res => res.data)
  },

  GET_HITS({ state, commit }, { eventName,  payload = {} }) {
    return http.axios.get(`events/${eventName}/hits`, { params: payload }).then(res => res.data)
  },

  POST_EVENT({}, payload) {
    return http.axios.post(`events`, payload).then(res => res.data)
  },

  PATCH_EVENT({}, { eventName,  payload =  {} }) {
    return http.axios.patch(`events/${eventName}`, payload).then(res => res.data)
  },

  DELETE_EVENT({}, { eventName }) {
    return http.axios.delete(`events/${eventName}`).then(res => res.data)
  },

  POST_HIT({}, { eventName, payload = {} }) {
    return http.axios.post(`events/${eventName}/hits`, payload).then(res => res.data)
  },

  PATCH_HIT({}, { eventName, hitId, payload = {} }) {
    return http.axios.patch(`events/${eventName}/hits/${hitId}`, payload).then(res => res.data)
  },

  DELETE_HIT({}, { eventName, hitId }) {
    return http.axios.delete(`events/${eventName}/hits/${hitId}`).then(res => res.data)
  },

}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}