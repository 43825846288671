import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import auth from './modules/auth'
import events from './modules/events'

Vue.use(Vuex)

const store = new Vuex.Store({

  state: {},

  mutations: {},

  actions: {},

  modules: {
		auth,
		events,
	},

})

axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response && error.response.status === 401) {
			store.commit('CLEAR_SESSION')
		}
		return Promise.reject(error)
	}
)

export default store
