<template>
  <section>

    <h1 class="title">Events</h1>

    <Events :hasChart="false" />

  </section>
</template>

<script>

import Events from '@/components/events/Events'

export default {

  name: 'EventsPage',

  components: {
    Events,
  },

}

</script>
