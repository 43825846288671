import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router/index.js'
import store from '@/store'

Vue.config.productionTip = false

import { http }  from './include/http'
Vue.use(http)

import directives from './include/directives'
Vue.use(directives)

// main scss files
import './assets/scss/main.scss'

// font-awesome loader
import './assets/icons'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch() 

import Spinner from './components/app/Spinner'
Vue.component('spinner', Spinner)

import { colorsByName } from '@/include/const'
Vue.prototype.$getColorByName = name => colorsByName[name]

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
