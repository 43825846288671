export default {

  install(Vue) {

    // bulma css classes
    let cssClasses = [
      'loading',
      'active',
    ]

    cssClasses.forEach(cssClass => {
      Vue.directive(cssClass, function(el, { value }) {
  
        if (value) el.classList.add(`is-${cssClass}`)
        else el.classList.remove(`is-${cssClass}`)
  
      })
    })

  },

}