<template>
  <section>

    <EventPanel :eventName="eventName" v-slot="{ event }">
      <HitPanel :eventName="eventName" :id="hitId" v-slot="{ hit, loading, methods: { post, patch }}">

        <h1 class="title is-4">{{event.name}}</h1>
        <h2 class="subtitle">Edit Hit</h2>

        <div class="box is-ancored">
          <HitForm :event="event" :base="hit" :loading="loading" @patch="patch" @post="post" />
        </div>

      </HitPanel>
    </EventPanel>

  </section>
</template>

<script>

import EventPanel from '@/components/events/EventPanel'
import HitForm from '@/components/hits/HitForm'
import HitPanel from '@/components/hits/HitPanel'

export default {

  name: 'HitEdit',

  components: {
    EventPanel,
    HitForm,
    HitPanel,
  },

  data() {

    let { eventName } = this.$route.params
    let hitId = this.$route.params.hitId

    return {
      eventName,
      hitId,
    }

  },

}

</script>