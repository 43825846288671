<template>
  <nav class="navbar is-justify-content-space-evenly is-fixed-bottom">

    <a class="navbar-item" @click="$router.back()">
      <h1 class="title is-4 has-text-grey-lighter">
        <fa :icon="[ 'fas', 'chevron-left' ]" />
      </h1>
    </a>

    <router-link class="navbar-item" to="/home">
      <h1 class="title is-4 has-text-grey-lighter">
        <fa :icon="[ 'fas', 'home' ]" />
      </h1>
    </router-link>

    <router-link class="navbar-item" to="/events">
      <h1 class="title is-4 has-text-grey-lighter">
        <fa :icon="[ 'fas', 'list' ]" />
      </h1>
    </router-link>

  </nav>
</template>

<script>

export default {

  name: 'NavbarBottom',

}

</script>

<style lang="scss" scoped>

@import "@/assets/scss/variables/all";

.router-link-active{
  
  h1 {
    color: gray !important;
  }

}

.navbar {
  box-shadow: inset 0px 1px 0px rgba(27, 31, 35, 0.04);
}

</style>
