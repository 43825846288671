<template>
  <section>

    <div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Name *</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input 
                class="input"
                type="text"
                maxlength="30"
                placeholder="Event Name"
                v-model.trim="model.name"
              >
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Description</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <textarea
                class="textarea"
                style="resize: none;"
                type="text"
                maxlength="255"
                placeholder="Event Description"
                v-model.trim="model.description"
              />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Prefix *</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input
                class="input"
                type="text"
                maxlength="10"
                placeholder="Example: Euros, Values, Times"
                v-model.trim="model.prefix"
              >
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Show values as stacked?</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <div class="control">
              <label class="radio">
                <input type="radio" name="member" :value="true" v-model="model.stacked">
                Yes
                <fa :icon="[ 'fas', 'chart-line' ]" size="1x" />
              </label>
              <label class="radio">
                <input type="radio" name="member" :value="false" v-model="model.stacked">
                No
                <fa :icon="[ 'fas', 'chart-bar' ]" size="1x" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Color</label>
        </div>
        <div class="field-body">
          <div class="field is-grouped is-grouped-multiline">
            <div class="control" v-for="color in availableColors" :key="color" >
              <label class="radio" :class="[ 'has-background-' + color ]" >
                <input type="radio" class="color-box" :value="color"  v-model="model.color_name">
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Icon</label>
        </div>
        <div class="field-body">
          <div class="buttons">
            <button class="button is-medium" v-for="availableIcon in availableIcons" :key="availableIcon" @click="setIcon(availableIcon)">
              <span class="icon" :class="[ 'has-text-' + ( model.icon == availableIcon ? model.color_name : 'grey-light' ) ]">
                <fa 
                  :icon="[ 'fas', availableIcon ]"
                />
              </span>
            </button>
          </div>
        </div>
      </div>

      <br>

      <div class="field is-horizontal has-text-right">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button class="button is-success is-hidden-mobile" v-loading="loading" @click="save" :disabled="!canSend">
                Save
              </button>
              <button class="button is-success is-fullwidth is-hidden-tablet" v-loading="loading" @click="save" :disabled="!canSend">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>

import { colorsByName } from '@/include/const'

export default {

  name: 'EventForm',

  props: {

    base: {
      type: Object,
      default() {
        return {}
      }
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  data() {

    let availableIcons = [
      'apple-alt',
      'basketball-ball',
      'bath',
      'bed',
      'beer',
      'book',
      'bus',
      'calendar',
      'capsules',
      'car',
      'chart-area',
      'check',
      'clock',
      'cloud-sun-rain',
      'comment',
      'cookie',
      'envelope',
      'location-arrow',
      'mask',
      'pencil-alt',
      'phone',
      'plug',
      'store',
      'tag',
      'toilet-paper',
      'user-friends',
      'walking',
      'wallet',
      'warehouse',
      'paper-plane',
      'desktop',
      'tv',
    ] 

    let model = JSON.parse(JSON.stringify(this.base))

    let baseFields = [
      'name',
      'prefix',
      'color_name',
      'icon',
      'stacked',
    ]

    let optionalFields = [
      'description',
    ]

    baseFields.forEach(key => { if(model[key] === undefined) model[key] = null })

    if (model.stacked === null) {
      model.stacked = false
    }

    return {
      model,
      baseFields,
      optionalFields,
      availableIcons,
    }
  
  },

  created() {
    if (!this.model.color_name) this.model.color_name = this.availableColors[0]
    if (!this.model.icon) this.model.icon = this.availableIcons[0]
  },

  computed: {

    isNew() {
      if (this.base.name) return false
      return true
    },

    edited() {
      return !Object.keys(this.model).every(key => this.model[key] === this.base[key])
    },

    allFieldsValid() {
      for (let field of this.baseFields) {

        if (this.optionalFields.includes(field)) continue

        let value = this.model[field]

        if (value === null || value === '') {

          return false
        }

      }

      return true
    },

    canSend() {
      return this.allFieldsValid && !this.loading
    },

    availableColors() {
      return Object.keys(colorsByName)
    },

  },

  methods: {

    setIcon(icon) {
      this.model.icon = icon
    },

    post() {
      this.$emit('post', this.model)
    },

    patch() {
      this.$emit('patch', this.model)
    },

    save() {
      if (this.isNew) this.post()
      else this.patch()
    },
  }

}

</script>

<style lang="scss" scoped>

@import "@/assets/scss/variables/all";

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'].color-box {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 40px;
    width: 60px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:not(.switch) {
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
  }

  input[type='radio'].color-box {
    &:after {
      width: 15px;
      height: 30px;
      left: 37%;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

}

</style>
