<template>
  <section>
    <HitsPanel :eventName="eventName" v-slot="{ hits, methods: { del } }">

      <h1 class="title">{{ eventName }}</h1>
      <h2 class="subtitle">Hits</h2>

      <Hit
        class="box is-ancored"
        v-for="hit in hits"
        :key="hit.id"
        :hit="hit"
        @delete="del"
      />

    </HitsPanel>
  </section>
</template>

<script>

import HitsPanel from '@/components/hits/HitsPanel'
import Hit from '@/components/hits/Hit'

export default {

  name: 'Hits',

  components: {
    HitsPanel,
    Hit,
  },

  data() {

    let { eventName } = this.$route.params

    return {

      eventName,

    }

  },

}

</script>