import Vue from 'vue'
import VueRouter from 'vue-router'

import app from '@/router/routes/app'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [].concat(
  app,
)

const router = new VueRouter({
  mode: 'history',
  routes
})

let userIsLogged = () => store.getters.isLogged
let tokenSaved = () => store.getters.token

router.beforeEach(async (to, from, next) => {

  if (to.name == 'logout') {
    await store.dispatch('LOGOUT')
    return next({ name: 'login' })
  }

  // il root per noi è la home
  if (to.name == 'root') {
    return next({ name: 'home' })
  }

  // se vado al login ma ho il token mando a root
  if (to.name == 'login' && tokenSaved()) {
    return next({ name: 'root' })
  }

  // se la pagina richiede l'auth e l'utente non è loggato
  if (!userIsLogged() && to.name != 'login') {

    // se non ho il token vado al login
    if (!tokenSaved()) {
      return next({ name: 'login' })
    }

    // se ho il token provo ad autenticarmi
    try {

      // setLoadingLoginTrue()
      await store.dispatch("LOGIN")

      if (!userIsLogged()) throw 'error'

    } catch (e) {
      // setLoadingLoginFalse()
      store.commit('CLEAR_SESSION')
      return next({ name: 'login' })
    }

    // setLoadingLoginFalse()
  }

  // default navigation
  return next()

})

export default router
