<template>
  <section>

    <EventPanel :eventName="eventName" v-slot="{ event, methods: { del } }">

      <div class="box is-ancored">

        <div class="columns is-align-items-center">

          <div class="column">
            <h1 class="title is-size-4-touch">{{ event.name }}</h1>
            <h2 class="subtitle is-size-5-touch">{{ dateH(event.updated_at) }}</h2>
          </div>

          <div class="column">
            <div class="buttons is-right">
              <button class="button" @click="deleteEvent() && del()">
                <span class="icon has-text-danger">
                  <fa :icon="[ 'fas', 'trash' ]"/>
                </span>
              </button>
              <button class="button" @click="goToEventEdit">
                <span class="icon has-text-gray">
                  <fa :icon="[ 'fas', 'pencil-alt' ]"/>
                </span>
              </button>
              <button class="button" @click="goToHits">
                <span class="icon has-text-gray">
                  <fa :icon="[ 'fas', 'list' ]"/>
                </span>
              </button>
              <button class="button" @click="goToHitCreate">
                <span class="icon has-text-gray">
                  <fa :icon="[ 'fas', 'plus' ]"/>
                </span>
              </button>
            </div>
          </div>
        </div>

      </div>

      <HitsPanel :eventName="eventName" :payload="hitsPayload"  v-slot="{ hits, value }">

        <section>

          <div class="box is-ancored">
            <div class="columns is-mobile has-text-centered">
              <div class="column">
                <p class="heading">TOTAL {{ event.prefix }}</p>
                <p class="title is-size-4-touch">{{ event.total_value }}</p>
              </div>
              <div class="column">
                <p class="heading">PERIOD {{ event.prefix }}</p>
                <p class="title is-size-4-touch">{{ value }}</p>
              </div>
              <div class="column">
                <p class="heading">PERIOD HITS</p>
                <p class="title is-size-4-touch">{{ hits.length }}</p>
              </div>
            </div>
          </div>

          <div class="box is-ancored">
            <div class="buttons is-right">
              <button class="button is-small" v-active="selectedOffset == offsetOneWeek" @click="changeSelectedOffset(offsetOneWeek)" >Last Week</button>
              <button class="button is-small" v-active="selectedOffset == offsetOneMonth" @click="changeSelectedOffset(offsetOneMonth)" >1m</button>
              <button class="button is-small" v-active="selectedOffset == offsetThreeMonths" @click="changeSelectedOffset(offsetThreeMonths)" >3m</button>
              <button class="button is-small" v-active="selectedOffset == offsetOneYear" @click="changeSelectedOffset(offsetOneYear)" >1y</button>
              <button class="button is-small" v-active="selectedOffset == null" @click="changeSelectedOffset(null)" >all</button>
            </div>
            <HitsChart
              :preset="2"
              :hits="hits" 
              :color="$getColorByName(event.color_name)" 
              :isStacked="event.stacked"
            />
          </div>

          <div v-if="event.description" class="box is-ancored">
            <h1 class="title is-5">Description</h1>
            <p>
              {{ event.description }}
            </p>
          </div>

        </section>

      </HitsPanel>

    </EventPanel>

  </section>
</template>

<script>

import EventPanel from '@/components/events/EventPanel'
import HitsChart from '@/components/hits/HitsChart'
import HitsPanel from '@/components/hits/HitsPanel'
import Hit from '@/components/hits/Hit'

export default {

  name: 'Event',

  components: {
    EventPanel,
    HitsChart,
    HitsPanel,
    Hit,
  },

  data() {

    let { eventName } = this.$route.params

    let offsetOneDay = 24*60*60*1000

    let offsetOneWeek = offsetOneDay * 7
    let offsetOneMonth = offsetOneDay * 30
    let offsetThreeMonths = offsetOneMonth * 3
    let offsetOneYear = offsetOneMonth * 12

    let dateTo = new Date()

    return {
      eventName,

      dateTo,

      offsetOneWeek,
      offsetOneMonth,
      offsetThreeMonths,
      offsetOneYear,

      selectedOffset: offsetOneMonth,
    }

  },

  computed: {

    hitsPayload() {
      if (!this.selectedOffset) return {}
      return { date_from: this.dateFrom, date_to: this.dateTo }
    },

    dateFrom() {
      let dateFrom = new Date()
      dateFrom.setTime(dateFrom.getTime() - this.selectedOffset)
      return dateFrom
    },

  },

  methods: {

    deleteEvent() {
      return window.confirm(`Delete event ${this.eventName}?`)
    },

    dateH(date) {
      return new Date(date + 'Z').toLocaleString()
    },

    changeSelectedOffset(offset) {
      this.selectedOffset = offset
    },

    goToHits() {
      this.$router.push({ name: 'hits', params: { eventName: this.eventName } })
    },

    goToEventEdit() {
      this.$router.push({ name: 'eventEdit', params: { eventName: this.eventName } })
    },

    goToHitCreate() {
      this.$router.push({ name: 'hitsNew', params: { eventName: this.eventName } })
    },

  },

}

</script>
