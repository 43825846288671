import { 
  http,
  TOKEN_NAME,
} from '@/include/http'

const state = {

  token: window.localStorage.getItem(TOKEN_NAME) || null,
  user: null,

}

const getters = {

  user: state => state.user,
  token: state => state.token,
  isLogged: state => state.user && true,

  username: state => {
    return state.user && state.user.username[0].toUpperCase() + state.user.username.slice(1).toLowerCase()
  },

}

const actions = {

  LOGIN ({ state, commit }, payload = null ) {
    return new Promise(async (resolve, reject) => {

      let response = null

      try {

        if (state.token) {
          response = await http.axios.get('session', {
            headers: { 
              Authorization: `Bearer ${ state.token }` 
            } 
          })
    
          response.data.data.token = state.token
          commit('LOGIN', response.data)
        }
    
        else if (payload) {
          response = await http.axios.post('login', payload)
          commit('LOGIN', response.data)
        }
  
      } catch (error) {
        console.error(error)
        commit('CLEAR_SESSION')
        reject(error)
      }

      resolve(response)

    })

  },

  LOGOUT({ commit }) {
    commit('CLEAR_SESSION')
  },

}

const mutations = {

  CLEAR_SESSION(state) {
    window.localStorage.removeItem(TOKEN_NAME)
    state.user = null,
    state.token = null
  },

  LOGIN(state, data) {
    let { user, token } = data.data
    window.localStorage.setItem(TOKEN_NAME, token)
    http.axios.defaults.headers.Authorization = 'Bearer ' + token
    state.token = token
    state.user = user
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}