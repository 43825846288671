<template>

  <section class="hero is-clickable" @click="goToEvent">
    <div class="hero-body p-3">

      <spinner v-if="loading" />

      <div class="columns is-align-items-center" v-else>
        <div class="column is-6">
          <div class="media">
            <div class="media-left">
              <span class="icon" :class="[ 'has-text-' + event.color_name ]">
                <fa :icon="[ 'fas', event.icon ]" style="width: auto; height: auto" />
              </span>
            </div>
            <div class="media-content" style="overflow: hidden">
              <h1 class="title is-4">{{ event.name }}</h1>
              <!-- <h2 v-if="hasChart" class="subtitle is-6">{{ event.description }}</h2> -->
            </div>
          </div>
        </div>

        <div class="column is-6" v-if="hasChart">
          <nav class="columns is-align-items-center">
            <div class="column is-4">
              <div class="columns is-align-items-center is-mobile">
                <div class="column">
                  <div class="tags has-addons">
                    <span class="tag is-medium">{{ event.total_value }}</span>
                    <span class="tag is-medium" :class="[ 'is-' + event.color_name ]">{{ event.prefix }}</span>
                  </div>
                </div>
                <!-- <div class="column is-narrow">
                  <button class="button is-small" @click.stop="goToHitCreate">
                    <span class="icon" :class="[ 'has-text-' + event.color_name ]">
                      <fa :icon="[ 'fas', 'plus' ]" size="1x" />
                    </span>
                  </button>
                </div> -->
              </div>
            </div>
            <div v-if="showHitsChart" class="column is-8">
              <HitsChart
                :preset="1"
                :hits="hits"
                :color="eventColor"
                :isStacked="event.stacked"
                style="max-height: 60px;"
              />
            </div>
          </nav>
        </div>

        <div class="column has-text-right" v-else>
          <div class="level is-mobile">
            <div class="level-left m-0">
              <div class="tags has-addons">
                <span class="tag is-medium">{{ event.total_value }}</span>
                <span class="tag is-medium" :class="[ 'is-' + event.color_name ]">{{ event.prefix }}</span>
              </div>
            </div>
            <div class="level-right m-0">
              <button class="button is-small" @click.stop="goToHitCreate">
                <span class="icon" :class="[ 'has-text-' + event.color_name ]">
                  <fa :icon="[ 'fas', 'plus' ]" size="1x" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script>

import HitsChart from '@/components/hits/HitsChart'

export default {

  name: 'EventPreview',

  components: {
    HitsChart,
  },

  props: {

    _event: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },

    hasChart: {
      type: Boolean,
      default: false
    },

  },

  data() {

    let event = this._event

    let dateOffset = (24*60*60*1000) * 14
    let dateFrom = new Date()
    dateFrom.setTime(dateFrom.getTime() - dateOffset)
    let dateTo = new Date()

    return {
      eventName: null,
      event,
      dateFrom,
      dateTo,
      hits: [],
      loadingFetchHits: false,
      loadingFetchEvent: false,
    }

  },

  created() {
    if (!this.event.name) this.fetch()
    else if(this.hasChart) this.fetchHits()
  },

  computed: {

    loading() {
      return this.loadingFetchEvent || this.loadingFetchHits
    },

    hasHits() {
      return this.hits.length > 0
    },

    showHitsChart() {
      return this.hits.length > 1
    },

    eventColor() {
      return this.$getColorByName(this.event.color_name)
    },

  },

  methods: {

    goToEvent() {
      this.$router.push({ name: 'event', params: { eventName: this.event.name }})
    },

    goToHitCreate() {
      this.$router.push({ name: 'hitsNew', params: { eventName: this.event.name } }) 
    },

    fetchHits() {

      this.loadingFetchHits = true

      this.$store.dispatch('GET_HITS', { 
          eventName: this.event.name,
          payload: { 
            dateFrom: this.dateFrom.toISOString(),
            dateTo: this.dateTo.toISOString()
          }
        })
        .then(res => {
          this.hits = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetchHits = false
        })

    },

    fetchEvent() {

      this.loadingFetchEvent = true

      this.$store.dispatch('GET_EVENT', { eventName: this.eventName })
        .then(res => {
          this.event = res.data
          this.fetchHits()
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetchEvent = false
        })

    }

  },

}

</script>
