import { library } from '@fortawesome/fontawesome-svg-core'

import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
library.add(faAsterisk)

import { faToilet } from '@fortawesome/free-solid-svg-icons'
library.add(faToilet)

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faPencilAlt)

import { faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faPlus)

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faChevronLeft)

import { faHome } from '@fortawesome/free-solid-svg-icons'
library.add(faHome)

import { faList } from '@fortawesome/free-solid-svg-icons'
library.add(faList)

import { faUser } from '@fortawesome/free-solid-svg-icons'
library.add(faUser)

import { faLock } from '@fortawesome/free-solid-svg-icons'
library.add(faLock)

import { faMinus } from '@fortawesome/free-solid-svg-icons'
library.add(faMinus)

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faSignOutAlt)

import { faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faTrash)

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
library.add(faEllipsisH)

import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faAppleAlt)

import { faBasketballBall } from '@fortawesome/free-solid-svg-icons'
library.add(faBasketballBall)

import { faBath } from '@fortawesome/free-solid-svg-icons'
library.add(faBath)

import { faBed } from '@fortawesome/free-solid-svg-icons'
library.add(faBed)

import { faBeer } from '@fortawesome/free-solid-svg-icons'
library.add(faBeer)

import { faBook } from '@fortawesome/free-solid-svg-icons'
library.add(faBook)

import { faBus } from '@fortawesome/free-solid-svg-icons'
library.add(faBus)

import { faCalendar } from '@fortawesome/free-solid-svg-icons'
library.add(faCalendar)

import { faCapsules } from '@fortawesome/free-solid-svg-icons'
library.add(faCapsules)

import { faCar } from '@fortawesome/free-solid-svg-icons'
library.add(faCar)

import { faChartArea } from '@fortawesome/free-solid-svg-icons'
library.add(faChartArea)

import { faCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faCheck)

import { faClock } from '@fortawesome/free-solid-svg-icons'
library.add(faClock)

import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons'
library.add(faCloudSunRain)

import { faComment } from '@fortawesome/free-solid-svg-icons'
library.add(faComment)

import { faCookie } from '@fortawesome/free-solid-svg-icons'
library.add(faCookie)

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faEnvelope)

import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
library.add(faLocationArrow)

import { faMask } from '@fortawesome/free-solid-svg-icons'
library.add(faMask)

import { faPhone } from '@fortawesome/free-solid-svg-icons'
library.add(faPhone)

import { faPlug } from '@fortawesome/free-solid-svg-icons'
library.add(faPlug)

import { faStore } from '@fortawesome/free-solid-svg-icons'
library.add(faStore)

import { faTag } from '@fortawesome/free-solid-svg-icons'
library.add(faTag)

import { faToiletPaper } from '@fortawesome/free-solid-svg-icons'
library.add(faToiletPaper)

import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
library.add(faUserFriends)

import { faWalking } from '@fortawesome/free-solid-svg-icons'
library.add(faWalking)

import { faWallet } from '@fortawesome/free-solid-svg-icons'
library.add(faWallet)

import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
library.add(faWarehouse)

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
library.add(faPaperPlane)

import { faDesktop } from '@fortawesome/free-solid-svg-icons'
library.add(faDesktop)

import { faTv } from '@fortawesome/free-solid-svg-icons'
library.add(faTv)

import { faChartLine } from '@fortawesome/free-solid-svg-icons'
library.add(faChartLine)

import { faChartBar } from '@fortawesome/free-solid-svg-icons'
library.add(faChartBar)
