<template>
  <div>

    <spinner v-if="loadingFetch" />

    <div v-else-if="result || !eventName">
      <slot :event="result" :loading="loading" :methods="{ get, post, patch, del }" />
    </div>

    <div v-else>
      Event with name <b>{{eventName}}</b> not found
    </div>

  </div>
</template>

<script>

export default {

  name: 'EventPanel',

  props: {

    eventName: {
      type: String,
      dafault: null,
    },

  },

  data() {

    return {
      loadingFetch: false,
      loadingPost: false,
      loadingPatch: false,
      loadingDelete: false,
      result: null,
    }
  
  },

  created() {
    if (this.eventName) this.get()
  },

  computed: {

    loading() {
      return this.loadingFetch || this.loadingPost || this.loadingPatch || this.loadingDelete
    },

  },

  methods: {

    get() {

      this.loadingFetch = true

      this.$store.dispatch('GET_EVENT', { eventName: this.eventName })
        .then(res => {
          this.result = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetch = false
        })

    },

    post(payload) {

      this.loadingPost = true

      this.$store.dispatch('POST_EVENT', payload)
        .then(res => {
          this.$router.push({ name: 'event', params: { eventName: res.data.name } })
        })
        .catch(() => {})
        .then(() => {
          this.loadingPost = false
        })

    },

    patch(payload) {

      this.loadingPatch = true

      this.$store.dispatch('PATCH_EVENT', { eventName: this.eventName, payload})
        .then(res => {
          this.$router.push({ name: 'event', params: { eventName: res.data.name } })
        })
        .catch(() => {})
        .then(() => {
          this.loadingPatch = false
        })

    },

    del() {

      this.loadingDelete = true

      this.$store.dispatch('DELETE_EVENT', { eventName: this.eventName })
        .then(res => {
          this.$router.push({ name: 'events' })
        })
        .catch(() => {})
        .then(() => {
          this.loadingDelete = false
        })

    },

  }

}

</script>