<template>
  <section>

    <div class="hero is-fullheight">

      <div style="position: absolute; width: 100%">
        <div class="huye has-text-centered">
          <div class="title is-1">
            HUYE
          </div>
        </div>
      </div>

      <div class="hero-body">
        <div class="container">

          <div class="columns">
            <div class="column is-4 is-offset-4">
              <div class="field">
                <div class="control has-icons-left">
                  <input class="input" type="text" placeholder="Username" v-model="username">
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <input class="input" type="password" placeholder="Password" v-model="password" v-on:keyup.enter="login">
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button 
                    class="button is-fullwidth"
                    v-loading="loading"
                    :disabled="isLoginButtonDisabled"
                    @click="login"
                  >
                    LOGIN
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </section>
</template>

<script>

export default {

  name: 'Login',

  data() {

    return {
      username: null,
      password: null,
      loading: false,
    }

  },
  
  created() {
    if (this.$store.getters.token) {
      this.login()
    }
  },

  computed: {

    canSend() {
      return (
        this.username !== null &&
        this.username !== '' &&
        this.password !== null &&
        this.password !== ''
      )
    },

    isLoginButtonDisabled() {
      return this.loading || !this.canSend
    },

  },

  methods: {

    login() {

      this.loading = true

      let { username, password } = this

      return this.$store.dispatch('LOGIN', { username, password })
        .then(res => {
          this.$router.push({ name: 'root' })
            .catch(e => {})
        })
        .catch(err => {

        })
        .then(() => {
          this.loading = false
        })

    }

  }

}

</script>

<style lang="scss" scoped>

@import "@/assets/scss/variables/all";

.huye .title {
  text-transform: uppercase;
  letter-spacing: 0.3ch;
} 
  
</style>
