<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">

    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <h1 class="title is-3 huye">huye</h1>
      </a>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start is-hidden-mobile">
        <router-link class="navbar-item" to="/home">
          Home
        </router-link>

        <router-link class="navbar-item" to="/events">
          Events
        </router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons" @click="logout">
            <button class="button is-light">
              <span class="icon">
                <fa :icon="[ 'fas', 'sign-out-alt' ]" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>

<script>

export default {

  name: 'Navbar',

  methods: {

    logout() {
      this.$router.push({ name: 'logout' })
        .catch(e => {})
    },

  },

}

</script>

<style scoped>

.huye {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
}
  

</style>