<template>
  <section>

    <EventsPanel v-slot="{ events }" :onlyRecent=onlyRecent >
      <BasicFilter :values="events" fieldSearch="name" :disabled="!hasSearch">

        <template v-slot="{ filtered }" >
          <br>
          <EventList :events="filtered" :hasChart="hasChart" />
        </template>

        <template v-slot:button>
          <button class="button" @click="$router.push({ name: 'eventNew' })">
            <span class="icon has-text-gray">
              <fa :icon="[ 'fas', 'plus' ]" size="1x" />
            </span>
          </button>
        </template>

      </BasicFilter>
    </EventsPanel>

  </section>
</template>

<script>

import EventsPanel from '@/components/events/EventsPanel'
import EventList from '@/components/events/EventList'
import { BasicFilter } from '@/include/utils'

export default {

  name: 'Events',

  components: {
    EventsPanel,
    EventList,
    BasicFilter,
  },

  props: {

    hasSearch: {
      type: Boolean,
      default: true,
    },

    hasCreate: {
      type: Boolean,
      default: true,
    },

    hasChart: {
      type: Boolean,
      default: true
    },

    onlyRecent: {
      type: Boolean,
      default: false,
    },

  }

}

</script>