<template>
  <section>

    <spinner v-if="loadingFetch" />

    <slot v-else-if="results" :events="results" />

    <div v-else>
      No events found
    </div>

  </section>
</template>

<script>

export default {

  name: 'EventsPanel',

  props: {

    onlyRecent: {
      type: Boolean,
      default: false,
    },

  },

  data() {

    return {
      loadingFetch: false,
      results: null,
    }

  },

  created() {
    this.fetch()
  },

  computed: {

    getEventActionName() {

      if (this.onlyRecent) return 'GET_RECENT_EVENTS'
      return 'GET_EVENTS'

    },

  },

  methods: {

    fetch() {

      this.loadingFetch = true

      this.$store.dispatch(this.getEventActionName)
        .then(res => {
          this.results = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingFetch = false
        })
    }

  }

}

</script>