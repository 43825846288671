<template>
  <section>

    <div class="">

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Date *</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input 
                class="input"
                type="datetime-local"
                placeholder="Name"
                v-model="date"
              >
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Value *</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <input 
                class="input"
                type="number"
                :placeholder="event.prefix"
                v-model.trim="model.value"
              >
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Notes</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control is-expanded">
              <textarea
                class="textarea"
                style="resize: none;"
                type="text"
                maxlength="50"
                placeholder="Notes"
                v-model.trim="model.notes"
              />
            </p>
          </div>
        </div>
      </div>

      <br>

      <div class="field is-horizontal has-text-right">
        <div class="field-label">
          <!-- Left empty for spacing -->
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <button class="button is-success is-hidden-mobile" v-loading="loading" @click="save" :disabled="!canSend">
                Save
              </button>
              <button class="button is-success is-fullwidth is-hidden-tablet" v-loading="loading" @click="save" :disabled="!canSend">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>

export default {

  name: 'HitForm',

  props: {

    base: {
      type: Object,
      default() {
        return {}
      }
    },

    event: {
      type: Object,
      default() {
        return {}
      }
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  data() {

    let model = JSON.parse(JSON.stringify(this.base))

    let baseFields = [
      'date',
      'value',
    ]

    let optionalFields = [
      'notes',
    ]

    baseFields.forEach(key => { if(model[key] === undefined) model[key] = null })

    let date = new Date()
    if (model.date) date = new Date(model.date + 'Z')

    var tzoffset = (date).getTimezoneOffset() * 60000
    var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0,16)

    date = localISOTime

    return {
      model,
      baseFields,
      optionalFields,
      date,
    }
  
  },

  computed: {

    isNew() {
      if (this.base.id) return false
      return true
    },

    edited() {
      return !Object.keys(this.modelPayload).every(key => this.modelPayload[key] === this.base[key])
    },

    allFieldsValid() {
      for (let field of this.baseFields) {

        if (this.optionalFields.includes(field)) continue

        let value = this.modelPayload[field]

        if (value === null || value === '') {

          return false
        }

      }

      return true
    },

    modelPayload() {
      return {
        ...this.model,
        date: new Date(this.date).toISOString(),
      }
    },

    canSend() {
      return this.allFieldsValid && !this.loading
    },

  },

  methods: {

    setIcon(icon) {
      this.model.icon = icon
    },

    post() {
      this.$emit('post', this.modelPayload)
    },

    patch() {
      this.$emit('patch', this.modelPayload)
    },

    save() {
      if (this.isNew) this.post()
      else this.patch()
    },

  }

}

</script>
