var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('EventPanel',{attrs:{"eventName":_vm.eventName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var event = ref.event;
return [_c('HitPanel',{attrs:{"eventName":_vm.eventName,"id":_vm.hitId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hit = ref.hit;
var loading = ref.loading;
var ref_methods = ref.methods;
var post = ref_methods.post;
var patch = ref_methods.patch;
return [_c('h1',{staticClass:"title is-4"},[_vm._v(_vm._s(event.name))]),_c('h2',{staticClass:"subtitle"},[_vm._v("Edit Hit")]),_c('div',{staticClass:"box is-ancored"},[_c('HitForm',{attrs:{"event":event,"base":hit,"loading":loading},on:{"patch":patch,"post":post}})],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }