<template>
  <line-chart v-if="isStacked" :chartData="chartData" :options="chartOptions"/>
  <bar-chart v-else :chartData="chartData" :options="chartOptions"/>
</template>

<script>

import LineChart from '@/include/LineChart'
import BarChart from '@/include/BarChart'

export default {

  name: 'HitsChart',

  components: {
    LineChart,
    BarChart,
  },

  props: {

    hits: {
      type: Array,
      default() {
        return []
      }
    },

    isStacked: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Object,
      default: null
    },

    color: {
      type: String,
      default: null
    },

    preset: {
      type: Number,
      default: 0,
    },

  },

  computed: {

    hasHits() {
      return this.hits.length > 0
    },

    chartOptions() {

      if (this.options) return this.options

      // homepage preview
      if (this.preset == 1) {
          return {
            animation: {
              duration: 0,
            },
            scales: {
              yAxes: [{
                display: false,
                ticks: {
                  stepSize: false,
                }
              }],
              xAxes: [{
                display: false,
                steppedLine: false,
                ticks: {
                  stepSize: false,
                },
                gridLines: {
                  drawTicks: false,
                },
              }],
            },

            layout: {
              padding: 2,
            },
            responsive: true,
            maintainAspectRatio: false,
            chart: {
              tooltip: {
                enabled: false
              },
            },
            legend: {
              display: false
            },
            title: {
              display: false,
            },


            elements: {

              line: {
                steppedLine: false,
                tension: 0.4,
                fill: false,
                borderColor: 5,
              }
            },
          }
        }

        if (this.preset == 2) {
          return {
            animation: {
              duration: 0,
            },
            scales: {
              yAxes: [{
                // display: false,
                ticks: {
                  stepSize: false,
                }
              }],
              xAxes: [{
                // display: false,
                type: 'time',
                bounds: 'ticks',
                steppedLine: false,
                ticks: {
                  stepSize: false,
                  source: 'auto',
                },
                time: {
                    unit: 'day'
                },
                // distribution: 'series',
                gridLines: {
                  drawTicks: true,
                  drawBorder: false,
                  drawOnChartArea: false,
                },
              }],
            },

            layout: {
              padding: 2,
            },
            responsive: true,
            maintainAspectRatio: false,
            chart: {
              tooltip: {
                // enabled: false
              },
            },
            legend: {
              display: false
            },
            title: {
              display: false,
            },

            elements: {

              line: {
                steppedLine: false,
                tension: 0,
                fill: false,
                borderColor: 6,
              }
            },
          }
        }

    },

    chartData() {

      let labels = []
      let dataset = {
        data: [],
        borderColor: this.color,
        backgroundColor: this.color,
      }

      if (!this.isStacked) {
        dataset = {
          ...dataset,
          barPercentage: 0.5,
          barThickness: 6,
          maxBarThickness: 8,
          minBarLength: 2,
        }
      } else {
        if (this.preset == 1) {
          dataset = {
            ...dataset,
            steppedLine: false,
            borderColor: this.color,
            pointRadius: 0,
          }
        } else {
          dataset.pointRadius = 5
        }
      }

      let value = 0

      this.hits.map(hit => {
        let localDate = new Date(hit.date + 'Z').toLocaleString()
        labels.push(localDate) 

        if (this.isStacked) {
          value += hit.value
          dataset.data.push(value)
        } else {
          dataset.data.push(hit.value)
        }

      })

      return {
        labels,
        datasets: [
          dataset
        ]
      }
    },

  },

}

</script>
