import Login from '@/views/Login'

import EventsWrapper from '@/views/events/EventsWrapper'
import Event from '@/views/events/Event'
import EventCreate from '@/views/events/EventCreate'
import EventsPage from '@/views/events/EventsPage'
import Hits from '@/views/hits/Hits'
import Homepage from '@/views/Homepage'
import EventEdit from '@/views/events/EventEdit'

import HitCreate from '@/views/hits/HitCreate'
import HitEdit from '@/views/hits/HitEdit'

export default [

  {
    path: '/',
    name: 'root',
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/logout',
    name: 'logout',
  },

  {
    path: '/home',
    component: Homepage,
    name: 'home',
  },

  {
    path: '/events',
    component: EventsWrapper,

    children: [

      {
        path: '',
        component: EventsPage,
        name: 'events',
      },

      {
        path: 'new',
        component: EventCreate,
        name: 'eventNew',
      },

      {
        path: ':eventName',
        component: Event,
        name: 'event',
      },

      {
        path: ':eventName/edit',
        component: EventEdit,
        name: 'eventEdit',
      },

      {
        path: ':eventName/hits',
        component: Hits,
        name: 'hits',
      },

      {
        path: ':eventName/hits/new',
        component: HitCreate,
        name: 'hitsNew',
      },

      {
        path: ':eventName/hits/:hitId/edit',
        component: HitEdit,
        name: 'hitEdit',
      },

    ]

  },

]